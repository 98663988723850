import type {HTMLAttributes} from 'react';

interface SectionProps extends HTMLAttributes<HTMLMetaElement> {
  children?: React.ReactNode;
  className?: string;
}

export default function Section({children, className}: SectionProps) {
  return <section className={className}>{children}</section>;
}
